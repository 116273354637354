import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/wcenatus/Documents/GitHub/cwa-site/src/templates/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://cwastaging.wpengine.com/wp-content/uploads/2015/05/receipt-return-ss-ps.jpg"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "300px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAAD/9oADAMBAAIQAxAAAAGuYjSbCB//xAAZEAADAQEBAAAAAAAAAAAAAAABAgMTEBL/2gAIAQEAAQUClNSQPTVGbTpn3//EABURAQEAAAAAAAAAAAAAAAAAAAAh/9oACAEDAQE/AYj/xAAWEQEBAQAAAAAAAAAAAAAAAAAAIVH/2gAIAQIBAT8Bq6//xAAZEAADAAMAAAAAAAAAAAAAAAAAAREQITH/2gAIAQEABj8CjdZETTHF3P8A/8QAGRABAQEBAQEAAAAAAAAAAAAAAREhABBB/9oACAEBAAE/IY0EafDhVA3LyBVC5y4DE6+f/9oADAMBAAIAAwAAABCA7//EABgRAAMBAQAAAAAAAAAAAAAAAAABESFR/9oACAEDAQE/EGmmE5P/xAAWEQEBAQAAAAAAAAAAAAAAAAABEQD/2gAIAQIBAT8QE2Ouv//EAB4QAQABBAIDAAAAAAAAAAAAAAERACExQRBRYXGh/9oACAEBAAE/EHLOJzB720aBVkd2PNWB1msXSPlNSBEuYoRu/fH/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Alibaba",
            "title": "Alibaba",
            "src": "/static/2b93d4dc62051750755c7c300a396813/8fc94/receipt-return-ss-ps-300x192.jpg",
            "srcSet": ["/static/2b93d4dc62051750755c7c300a396813/8675c/receipt-return-ss-ps-300x192.jpg 100w", "/static/2b93d4dc62051750755c7c300a396813/3cc80/receipt-return-ss-ps-300x192.jpg 200w", "/static/2b93d4dc62051750755c7c300a396813/8fc94/receipt-return-ss-ps-300x192.jpg 300w"],
            "sizes": "(max-width: 300px) 100vw, 300px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`Alibaba has started using a modified version of a QR code to fight counterfeit fraud and enable individualized marketing.  And the payments potential for such an offering is huge, delivering something akin to a non-wireless version of item-level RFID.`}</p>
    <p>{`The new code is like a QR code only in appearance, and that appearance was chosen for the familiarity that consumers and merchants have with scanning a QR code. The identifier, which is unique to each individual item on a store's shelf, can add a huge amount of data to each purchase. For example, a retailer who suspects a fraudulent return — an item being returned that was purchased elsewhere or stolen — can use the code to determine whether that item came from its inventory and whether it was paid for.`}</p>
    <p>{`"It certainly closes a door that exposes a retailer to a great deal of expense, by virtually eliminating return fraud," said Thad Peterson, a senior payments analyst at The Aite Group. "If every product has a unique identifier, then any time a product is returned to the store, the nature of the transaction prior to that return can be determined, including if there ever was one."`}</p>
    <p>{`This type of fraud exploits store policies that allow for returns without a receipt. But there are other uses for this technology at the point of sale. "This adds a massive amount of data to CRM and general data management, throwing in so much incremental data that you simply didn't have before," Peterson said.`}</p>
    <p>{`Alibaba has been working with an Israeli tech company called Visualead and has licensed its Dotless Visual Code Technology to use in these bar codes. L’Oréal and Ferrero also use this technology. In Alibaba's case, the codes can be read by the Chinese e-commerce company's Taobao mobile app.`}</p>
    <p>{`Read the rest of this article on `}<a parentName="p" {...{
        "href": "http://www.paymentssource.com/news/risk-analytics/alibabas-new-bar-code-could-fight-return-fraud-3021416-1.html?utm_medium=email&ET=paymentssource%3Ae4988932%3Aa%3A&utm_campaign=-may%2026%202015&utm_source=newsletter&st=email"
      }}>{`PaymentsSource`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      